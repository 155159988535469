export const AttendanceReportsExportColumnsOrder = [
  "Sr. No.",
  "Guard code",
  "Name",
  "Company Name",
  "Location",
  "Designation",
  "Check-in",
  "Check-out",
  "Worked hours",
  "Assign Checkpoint",
  "Shift",
  "Date",
  // 'map'
];

export const keyMappingForAttendanceReports = {
  guard_code: "Guard code",
  username: "Name",
  company_name: "Company Name",
  location_name: "Location",

  designation_name: "Designation",
  start_time: "Check-in",
  end_time: "Check-out",
  worked_hours: "Worked hours",
  checkout_pointname: "Assign Checkpoint",
  shift_name: "Shift",
  date: "Date",
};

export const GetLockReportsExportColumnsOrder = [
  "Sr. No.",
  "Guard code",
  "Name",
  "Company Name",
  "Location",
  "Designation",
  "Gate close",
  "Other Guard on Duty",
  "Assign Checkpoint",
  "Date",
  // 'map'
];

export const keyMappingForGetLockReports = {
  guardcode: "Guard code",
  username: "Name",
  company_name: "Company Name",
  location_name: "Location",
  designation_name: "Designation",
  gate_close_time: "Gate close",
  other_guard_name: "Other Guard on Duty",
  checkpoint_name: "Assign Checkpoint",
  gate_close_date: "Date",
};


export const keyMappingForTresspassingReports = {
  guardcode: "Guard code",
  username: "Name",
  company_name: "Company Name",
  location_name: "Location",
  designation_name: "Designation",
  alert_created_time: "Time",
  alert_created_date: "Date",
  alert_message: "Note",
  
};


export const keyMappingForSalaryReports = {
  guard_code: "Guard code",
  user_name: "Name",
  company_name: "Company Name",
  location_name: "Location",
  designation_name: "Designation",
  vendor_name: "Vendor",
  date_of_joining: "Date of Joining",
  total_days_of_month: "Total Days in Month",
  month_year: "Present Month-Year",
  salary: "Salary",
  net_pay: "Net Pay",
  total_shift: "Total Shift worked/Month",
  total_worked_hours: "Total Worked Hours",
  
};

export const SalaryReportsExportColumnsOrder = [
  "Sr. No.",
  "Guard code",
  "Name",
  "Company Name",
  "Location",
  "Designation",
  "Vendor",
  "Date of Joining",
  "Worked hours",
  "Total Days in Month",
  "Present Month-Year",
  "Salary",
  "Net Pay",
  "Total Shift worked/Month",
  "Total Worked Hours",
  // 'map'
  
];
