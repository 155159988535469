import React, { useEffect, useState } from "react";
import Select from "react-select";
import { handleExportData } from "./DataTable";
import { useForm, Controller } from "react-hook-form";
import { axiosInstance } from "../utils/commonInstance";
import { showToast } from "../hooks/showToast";
import { useLocation } from "react-router-dom";

const Listfilter = ({
  toggleFormFillrter,
  setGlobalFilter,
  filterOption,
  title,
  isExport = false,
  data,
}) => {
  const [fillter, setFillter] = useState({ selectFillter: "", search: "" });
  const [selectFilter, setSelectFilter] = useState(null);
  const [roleRightsData, setRoleRightsData] = useState([]);
  const { setValue } = useForm({});
  const location = useLocation(); 
  const roleId = localStorage.getItem("role_id");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axiosInstance.get(`web/getrolerightsdata/${roleId}`);
        const rightsData = response?.data?.data || [];
        // alert(rightsData);
        console.log(rightsData);
        setRoleRightsData(rightsData);
        rightsData.forEach((right) => {
          setValue(right, true);
        });
      } catch (error) {
        showToast("Error fetching role rights data", "error");
      }
    }
    if (roleId) {
      fetchData();
    }
  }, [roleId, setValue]);

  const pagePermissions = {
    "/userlist": ["user.add"],
    "/companymasterlist": ["company.add"],
    "/locationList": ["location.add"],
    "/companylist": ["companylocation.add"],
    "/vendorlist": ["vendor.add"],
    "/shiftlist": ["shift.add"],
    "/designationlist": ["designation.add"],
    "/checkpointlist": ["checkpoint.add"],
    "/roleList": ["role.add"],
    "/attendanceReports": ["attendance.export"],
    "/getfire": ["firealertreport.export"],
    "/getLockReports": ["gatelockreport.export"],
    "/getTrespassing": ["trespassingreport.export"],
    "/getpanicmode": ["panicmodereport.export"],
    "/getsalaryreport": ["salaryreport.export"],
  };
  const checkPermission = (roleRightsData, permission) =>
    !roleRightsData.includes(permission);
  const currentPath = location.pathname;
  const requiredPermissions = pagePermissions[currentPath] || [];

  useEffect(() => {
    if (title) {
      setSelectFilter(null);
      setFillter({ selectFillter: "", search: "" });
    }
  }, [title]);

  return (
    <>
      <div class="mb-4 row gy-2">
        <div class="col-xxl-2 col-xl-3 col-sm-3">
          <Select
            onChange={(e) => {
              setSelectFilter(e);
              setFillter((prevState) => ({
                ...prevState,
                selectFillter: e?.value,
              }));
            }}
            value={selectFilter}
            options={filterOption}
            placeholder="All"
            components={{ IndicatorSeparator: () => null }}
            styles={{
              control: (base, state) => ({
                ...base,
                border: state.isFocused
                  ? "1px solid #222e3c"
                  : "1px solid #ced4da",
                // This line disable the blue border
                boxShadow: state.isFocused
                  ? "1px solid #222e3c"
                  : "1px solid #222e3c",
                "&:hover": {
                  border: state.isFocused
                    ? "1px solid #222e3c"
                    : "1px solid #222e3c",
                },
              }),
              menu: (provided, state) => ({
                ...provided,
                zIndex: 9999,
              }),
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999, // your desired height
              }),
            }}
          />
        </div>
        <div class="col-xxl-2 col-xl-3 col-sm-3">
          <input
            onChange={(e) => {
              setFillter((prevState) => ({
                ...prevState,
                search: e?.target?.value,
              }));
            }}
            value={fillter?.search}
            type="search"
            id="search"
            name="search"
            class="form-control py-2 fw-medium"
            placeholder="Enter keyword"
          />
        </div>
        <div class="col-xl-3 col-sm-1 col-xxl-2 col-12 text-start mt-sm-2 mt-3">
          <button
            onClick={() => {
              setGlobalFilter(fillter);
            }}
            class="btn btn-lg btn-primary px-3 h-100"
          >
            Search
          </button>

          <button
            onClick={() => {
              setFillter({ selectFillter: "", search: "" });
              setGlobalFilter({ selectFillter: "", search: "" });
              setSelectFilter(null);
            }}
            style={{ marginLeft: "10px" }}
            class="btn btn-lg btn-primary px-3 h-100"
          >
            Clear
          </button>
        </div>

        <div class="col-sm-5 col-xl-3 col-xxl-6 col-12 text-start mt-sm-2 mt-3">
          {!isExport ? (
            <button
              onClick={() => {
                toggleFormFillrter(true);
              }}
              className="btn btn-lg float-end btn-primary px-3 h-100"
              disabled={requiredPermissions.some((permission) =>
                checkPermission(roleRightsData, permission)
              )}
            >
              Add
            </button>
          ) : (
            <button
              onClick={() => {
                if (data?.length) handleExportData(data);
              }}
              class="btn btn-lg float-end btn-primary px-3 h-100"
              disabled={requiredPermissions.some((permission) =>
                checkPermission(roleRightsData, permission)
              )}
            >
              Export
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Listfilter;
