import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { axiosInstance } from "../../utils/commonInstance";
import { showToast } from "../../hooks/showToast";
import { BackButtonModal } from "../../components/BackButtonModal";
import { checkObjectForNullUndefinedOrEmpty } from "../../utils/commonFunction";

const schema = yup.object().shape({
  appVersionCode: yup
    .string()
    .required("Please Enter App Version Code")
    .matches(/^(?!0)\d{1,2}(\.\d{1})?$/, 'App Version Code must be in the format XX.X')
    .test('is-valid', 'Invalid format', (value) => {
      if (!value) return true;
      const number = parseFloat(value);
      return number === parseFloat(number.toFixed(1));
    }),
  appVersionNo: yup
    .string()
    .required("Please Enter App Version No.")
    .matches(/^(?!0)\d{1,2}(\.\d{1,2})?$/, 'App Version No. must be in the format XX.XX')
    .test('is-valid', 'Invalid format', (value) => {
      if (!value) return true;
      const number = parseFloat(value);
      return number === parseFloat(number.toFixed(2));
    }),
  fileUpload: yup
    .mixed()
    .test("required", "Please Upload File", (value) => {
      return value && value.length > 0;
    })
    .test("fileType", "Only apk files are allowed", (value) => {
      console.log("value", value);
      if (!value || !value.length) return false;
      const file = value;
      console.log("file", file);
      const extension = file?.split(".")?.pop()?.toLowerCase();
      console.log("extension", extension);
      return extension === "apk";
    }),
  description: yup.string().required("Please Enter Description"),
  date: yup.string(),
});

const ApkConfigurationForm = ({
  toggleFormFillrter,
  manualFetchData,
  setGlobalFilter,
  isView,
  isEdit,
  previousData,
}) => {
  const [modal, setModal] = useState({
    isOpen: false,
  });

  const handleModalopen = () => {
    setModal((prevData) => ({
      ...prevData,
      isOpen: true,
    }));
  };

  const handleClose = () => {
    setModal((prevData) => ({
      ...prevData,
      isOpen: false,
    }));
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const handleBlur = (field) => {
    let value = getValues(field);
    if (value) {
      // Remove leading zeros
      value = value.replace(/^0+/, '');

      // Check if value already has one decimal place and do not add extra zeros
      if (field === 'appVersionCode' || field === 'appVersionNo') {
        const parts = value.split('.');
        if (parts.length === 2) {
          // Remove trailing zeros in the decimal part only if necessary
          parts[1] = parts[1].replace(/0+$/, '');
          value = parts.join('.');
        }
        setValue(field, value);
      }
    }
  };
  // const handleBlur = (field) => {
  //   let value = getValues(field);
  //   if (value) {
  //     // Remove leading zeros
  //     value = value.replace(/^0+/, '');

  //     if (field === 'appVersionCode' || field === 'appVersionNo') {
  //       const parts = value.split('.');
  //       if (parts.length === 2) {
  //         parts[1] = parts[1].replace(/0+$/, ''); // Remove trailing zeros
  //         value = parts.join('.');
  //       }
  //       setValue(field, value);
  //     }
  //   }
  // };
  const onSubmit = async (data) => {
    try {
      console.log("data", data);
      const dynamicEndPoint = isEdit
        ? `web/updaterole/${previousData?.id}`
        : "web/saverole";
      const method = isEdit ? "put" : "post";

      const response = await axiosInstance({
        method: method,
        url: dynamicEndPoint,
        data: {
          ...data,
        },
      });
      if (response?.data?.error) {
        showToast(response?.data?.message, "error");
        return false;
      }
      toggleFormFillrter();
      manualFetchData();
      showToast(response?.data?.message, "success");
    } catch (error) {
      showToast(error?.data?.message, "error");
    }
  };

  useEffect(() => {
    if (isView || isEdit) {
      setValue("name", previousData?.name);
      setValue("is_active", previousData?.is_active);
    }
  }, [isEdit, isView, previousData, setValue]);

  let result = checkObjectForNullUndefinedOrEmpty(watch());

  return (
    <>
      <section className="userlist-newuser content mobile">
        <div className="d-flex justify-content-end align-items-center mb-4 gap-3 flex-wrap">
          <h3 className="mb-0">
            <strong>
              {isEdit ? "Update" : isView ? "View" : "Add"} App Configuration
              Details
            </strong>
          </h3>
          <button
            onClick={() =>
              result && !isView ? handleModalopen() : toggleFormFillrter()
            }
            class="btn btn-lg btn-primary px-md-5 px-4 addlistuser"
          >
            Back
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="user-details">
            <div className="card">
              <div className="row card-body justify-content-around py-md-5 py-4 align-items-center">
                <div className="col-lg-5 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="shift_name" className="form-label">
                      App Version Code<span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="appVersionCode"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          type="number"
                          className="form-control py-2"
                          onBlur={(e) => {
                            field.onBlur(e);
                            handleBlur("appVersionCode");
                          }}
                        />
                      )}
                    />

                    {errors.appVersionCode && (
                      <p className="text-danger">
                        {errors.appVersionCode.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-lg-5 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="shift_name" className="form-label">
                      App Version No.<span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="appVersionNo"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          type="number"
                          className="form-control py-2"
                          onBlur={(e) => {
                            field.onBlur(e);
                            handleBlur("appVersionNo");
                          }}
                        />
                      )}
                    />

                    {errors.appVersionNo && (
                      <p className="text-danger">
                        {errors.appVersionNo.message}
                      </p>
                    )}
                  </div>
                </div>
                <div class="col-lg-5 col-md-6">
                  <div class="form-group mb-3">
                    <label for="agreement_file" class="form-label">
                      UPLOAD AGREEMENT<span class="text-danger">*</span>
                    </label>
                    {!isView && (
                      <Controller
                        name="fileUpload"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            {...field}
                            type="file"
                            accept=".apk"
                            className="form-control py-2"
                          />
                        )}
                      />
                    )}

                    {(isView || isEdit) && previousData?.agreement_file_url && (
                      <div className="pt-2">
                        <a
                          href={previousData.agreement_file_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Uploaded Agreement
                        </a>
                      </div>
                    )}

                    <p className="text-danger">{errors?.fileUpload?.message}</p>
                  </div>
                </div>

                <div class="col-lg-5 col-md-6">
                  <div class="form-group mb-3">
                    <label for="Description" class="form-label">
                      Description<span class="text-danger">*</span>
                    </label>
                    {!isView && (
                      <Controller
                        name="description"
                        id="description"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <textarea
                            className="form-control py-2"
                            {...field}
                            rows={4}
                          />
                        )}
                      />
                    )}

                    <p className="text-danger">
                      {errors?.description?.message}
                    </p>
                  </div>
                </div>

                <div class="col-lg-5 col-md-6">
                  <div class="form-group mb-3">
                    <label for="date" class="form-label">
                    From Date<span class="text-danger">*</span>
                    </label>

                       {!isView && (
                      <Controller
                        name="date"
                        id="date"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            {...field}
                            type="text"
                            className="form-control py-2"
                            value={new Date().toLocaleDateString()}
                            readOnly
                          />
                        )}
                      />
                    )}

                    <p className="text-danger">
                      {errors?.date?.message}
                    </p>
                  </div>
                </div>

                <div className="col-12 text-center mt-lg-5 mt-4">
                  <button
                    disabled={isView}
                    className="btn btn-lg btn-primary px-5"
                    type="submit"
                  >
                    {isEdit ? "UPDATE" : "SUBMIT"}
                  </button>
                  <button
                    disabled={isView || isEdit}
                    class="btn btn-lg btn-primary px-5 mx-xl-4"
                    type="button"
                    onClick={() => reset()}
                  >
                    CLEAR
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <BackButtonModal
          isOpen={modal?.isOpen}
          handleClose={handleClose}
          toggleFormFillrter={toggleFormFillrter}
        />
      </section>
    </>
  );
};

export default ApkConfigurationForm;
