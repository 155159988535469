export const userColumnsOrder =[
    'Sr. No.',
    'guard_code',
    'username',
    'email',
    'mobile_no',
    'date_of_joining',
    'role_name',
    'company_name',
    'location_name',
    'designation_name',
    'checkpoints',
    'vendor_name',
    'preview',
    'salary',
    'shift_name',
    'is_active','action'
]

export const locationColumnsOrder =[
    'Sr. No.',
    'location_name',
    'location_is_active',
    'created_at',
    'action'
]

export const vendorColumnsOrder =[
    'Sr. No.',
    'vendor_name',
    'email',
    'mobile_no',
    'gst_no',
    'tan_no',
    'pan_no',
    'epfo_no',
    'security_certificate_no',
    'preview',
    'is_active',
    'action'
    
]

export const CompanymasterColumnsOrder =[
    'Sr. No.',
    'company_name',
    'is_active',
    'created_at',
    'action'
]



export const CompanyColumnsOrder =[
    'Sr. No.',
    'company_name',
    'location_name',
    'company_longitude',
    'company_latitude',
    'created_at',
    'is_active',
    'action'
]


export const CheckpointColumnsOrder =[
    'Sr. No.',
    'company_name',
    'location_name',
    'checkpoint_name',
    'checkpoint_longitude',
    'checkpoint_latitude',
    'created_at',
    'is_active',
    'action'
]


export const DesignationColumnsOrder =[
    'Sr. No.',
    'designation',
    'company_name',
    'location_name',
    'vendor_name',
    'salary',
    'action'
]

export const RolesColumnsOrder =[
    'Sr. No.',
    'name',
    'created_at',
    'is_active',
    'action',
]

export const ShiftColumnsOrder =[
    'Sr. No.',
    'company_name',
    'location_name',
    'shift_name',
    'start_time',
    'end_time',
    'created_at',
    'is_active',
    'action'
    
]


export const AttendanceReportsColumnsOrder =[
    'Sr. No.',
    'guard_code',
    'username',
    'company_name',
    'location_name',
    'designation_name',
    'checkin_time',
    'checkout_time',
    'worked_hours',
    'checkout_pointname',
    'shift_name',
    'date',
    'preview',
     'map'
]


export const GetLockReportsColumnsOrder =[
    'Sr. No.',
    'guardcode',
    'username',
    'company_name',
    'location_name',
    'designation_name',
    'gate_close_time',
    'other_guard_name',
    'checkpoint',
    'gate_close_date',
    'preview',    
]


export const TrespassingColumnsOrder =[
    'Sr. No.',
    'guard_code',
    'username',
    'company_name',
    'location_name',
    'designation_name',
    'alert_created_time',
    'alert_created_date',
    'alert_message',
    'voicenote', 
]

export const SalaryColumnsOrder =[
    'Sr. No.',
    'guard_code',
    'user_name',
    'company_name',
    'location_name',
    'designation_name',
    'vendor_name',
    'date_of_joining',
    'total_days_of_month',
    'month_year', 
    'salary',
    "net_pay",
    'total_shift', 
    'total_worked_hours', 
]