/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect } from "react";
import HeaderLayout from "../../layouts/admin/HeaderLayout";
import FooterLayout from "../../layouts/admin/FooterLayout";
import Listfilter from "../../components/ListFilter";
import MaterialDataGrid from "../../components/DataTable";
import { axiosInstance } from "../../utils/commonInstance";
import useDataFetching from "../../hooks/useDataFatching";
import { Edit, Eye } from "feather-icons-react";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { showToast } from "../../hooks/showToast";

const fetchDataForPlatform = async (params, endpoint) => {
  return await axiosInstance.get(`web/${endpoint}`, { params });
};

const ListPage = ({
  title,
  ComonComponanatList,
  dynamiColumns,
  endPoint,
  filterOption,
  columnOrder,
}) => {
  const {
    state,
    columnFilters,
    setColumnFilters,
    setGlobalFilter,
    pagination,
    setPagination,
    manualFetchData,
  } = useDataFetching(fetchDataForPlatform, endPoint);
  const location = useLocation();
  const [roleRightsData, setRoleRightsData] = useState([]);
  const { setValue } = useForm({});
  const [calculations, setCalculations] = useState(0);
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [previousData, setPreviousData] = useState({});
  const [modal, setModal] = useState({
    isOpen: false,
    headerContent: "",
    subHeaderContent: "",
    data: {},
    clicked: null,
    text: "",
  });

  const roleId = localStorage.getItem("role_id");
  
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axiosInstance.get(`web/getrolerightsdata/${roleId}`);
        const rightsData = response?.data?.data || [];
        // alert(rightsData);
        // console.log(rightsData);
        setRoleRightsData(rightsData);
        rightsData.forEach((right) => {
          setValue(right, true);
        });
      } catch (error) {
        showToast("Error fetching role rights data", "error");
      }
    }
    if (roleId) {
      fetchData();
    }
  }, [roleId, setValue]);

  const pagePermissions = {
    "/userlist": ["user.edit"],
    "/companymasterlist": ["company.edit"],
    "/locationList": ["location.edit"],
    "/companylist": ["companylocation.edit"],
    "/vendorlist": ["vendor.edit"],
    "/shiftlist": ["shift.edit"],
    "/designationlist": ["designation.edit"],
    "/checkpointlist": ["checkpoint.edit"],
    "/roleList": ["role.edit"],
  };
  const checkPermission = (roleRightsData, permission) =>
    !roleRightsData.includes(permission);
  const currentPath = location.pathname;
  const requiredPermissions = pagePermissions[currentPath] || [];

  useEffect(() => {
    setIsView(false);
    setPreviousData({});
    setIsEdit(false);
    setFormState(false);
  }, [title]);

  const handleViewClick = (row) => {
    setIsView(true);
    setPreviousData(row);
    setIsEdit(false);
    setFormState(true);
    // Add your view logic here
  };

  const handleEditClick = (row) => {
    setIsEdit(true);
    setIsView(false);
    setPreviousData(row);
    setFormState(true);
    // Add your edit logic here
  };

  const handlePreviewClick = (row) => {
    setModal((prevData) => ({
      ...prevData,
      isOpen: true,
      header: "Document Preview",
      data: row,
    }));
    // Add your edit logic here
  };

  const handleClose = () => {
    setModal((prevData) => ({
      ...prevData,
      isOpen: false,
      header: "",
      data: {},
    }));
  };
  useEffect(() => {
    // Assuming OpenNewPagination is an object with properties pageIndex and pageSize
    const calculation = pagination.pageIndex * pagination.pageSize;
    // Set test to 0 if OpenNewPagination.pageIndex is 0, otherwise use the calculation
    setCalculations(calculation);
  }, [pagination.pageIndex, pagination.pageSize]);

  const dynamicColumns = useMemo(() => {
    const columns = [];
    // Add dynamic columns
    columns.push({
      header: "Sr. No.",
      Cell: ({ row }) => {
        const number = calculations + (row?.index + 1);
        const formattedNumber = number < 10 ? `0${number}` : number;
        return <div className="sr-no">{formattedNumber}</div>;
      },
      maxSize: 15,
      minSize: 10,
    });

    dynamiColumns?.forEach((col) => {
      if (col.accessorKey === "action") {
        // Customize the Cell renderer for the actions column
        columns.push({
          ...col,
          Cell: ({ row }) => (
            <div style={{ display: "flex" }}>
              <>
                <div
                  onClick={() => handleViewClick(row?.original)}
                  style={{ marginRight: "5px", cursor: "pointer" }}
                >
                  <Eye />
                </div>
                <button
                  className="btn p-0 m-0 ms-4"
                  onClick={() => handleEditClick(row?.original)}
                  style={{ cursor: "pointer" }}
                  disabled={requiredPermissions.some((permission) =>
                    checkPermission(roleRightsData, permission)
                  )}
                >
                  <Edit style={{ height: "18px", width: "18px" }} />
                </button>
              </>
            </div>
          ),
        });
      } else if (col.accessorKey === "preview") {
        // Customize the Cell renderer for the actions column
        columns.push({
          ...col,
          Cell: ({ row }) => (
            <div onClick={() => handlePreviewClick(row?.original)}>
              <a role="button" className="text-decoration-underline">
                Preview
              </a>
            </div>
          ),
        });
      } else {
        columns.push(col);
      }
    });
    return columns;
  }, [dynamiColumns, calculations, requiredPermissions, roleRightsData]);

  useEffect(() => {
    setFormState(false);
  }, [endPoint]);

  const [formState, setFormState] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "max-content",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleBackButton = () => {
    setIsView(false);
    setIsEdit(false);
    setFormState(false);
    setPreviousData({});
  };

  return (
    <>
      <div className="main">
        <HeaderLayout title={title} />
        {!formState ? (
          <section class="user-listmain content web">
            <>
              <Listfilter
                toggleFormFillrter={setFormState}
                setGlobalFilter={setGlobalFilter}
                filterOption={filterOption}
                title={title}
                data={[]}
              />
              <MaterialDataGrid
                columns={dynamicColumns}
                data={state?.data}
                rowCount={state?.rowCount}
                isError={state?.isError}
                handleColumnFilter={setColumnFilters}
                handlePagination={setPagination}
                initialState={{
                  columnFilters: columnFilters,
                  isLoading: state?.isLoading,
                  pagination: pagination,
                  showAlertBanner: state?.isError,
                  showProgressBars: state?.isRefetching,
                  columnOrder: columnOrder ? columnOrder : [],
                }}
              />
            </>
          </section>
        ) : (
          <ComonComponanatList
            toggleFormFillrter={handleBackButton}
            manualFetchData={manualFetchData}
            setGlobalFilter={setGlobalFilter}
            isView={isView}
            isEdit={isEdit}
            previousData={previousData}
          />
        )}
        {/* {formState && <ComonComponanatList toggleFormFillrter={setFormState} />} */}
        <FooterLayout />

        <Modal
          open={modal?.isOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ position: "elative", overflow: "hidden" }}>
            <IconButton
              sx={{ position: "absolute", top: 8, right: 8 }}
              onClick={handleClose}
            >
              <Close />
            </IconButton>
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {modal?.header}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {location?.pathname === "/userlist" ? (
                  <>
                    <label>Aadharcard File:</label> <br />
                    <a href={modal.data?.adharcard_file_url} target="_blank">
                      {modal.data?.adharcard_file_url}
                    </a>{" "}
                    <br />
                    <label>Police Verification Image:</label> <br />
                    <a
                      href={modal.data?.police_verification_image_url}
                      target="_blank"
                    >
                      {modal.data?.police_verification_image_url}
                    </a>
                    <br />
                    <label>Gun Licence Image:</label> <br />
                    <a href={modal.data?.gunlicence_image_url} target="_blank">
                      {modal.data?.gunlicence_image_url}
                    </a>
                    <br />
                    <label>Residance Image:</label> <br />
                    <a href={modal.data?.residence_image_url} target="_blank">
                      {modal.data?.residence_image_url}
                    </a>
                  </>
                ) : (
                  <>
                    <label>Agreement File:</label> <br />
                    <a href={modal.data?.agreement_file_url} target="_blank">
                      {modal.data?.agreement_file_name}
                    </a>
                  </>
                )}
              </Typography>
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
};
export default ListPage;
