import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { axiosInstance } from "../../utils/commonInstance";
import { convertToBase64 } from "../../utils/commonFunction";
import * as moment from "moment";
import { showToast } from "../../hooks/showToast";
import { checkObjectForNullUndefinedOrEmpty } from "../../utils/commonFunction";
import HeaderLayout from "../../layouts/admin/HeaderLayout";
import FooterLayout from "../../layouts/admin/FooterLayout";

const RoleRights = () => {
  const [roleRightsData, setRoleRightsData] = useState([]);
  const [roleOption, setRoleOption] = useState([]);

  const { register, handleSubmit, control, setValue, formState, watch } =
    useForm({});

  useEffect(() => {
    async function fetchData() {
      const roleResponse = await axiosInstance.get(`web/roles`);
      setRoleOption(roleResponse?.data?.data);
    }
    fetchData();
  }, []);

  const selectedRoleId = watch("roles");
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axiosInstance.get(
          `web/getrolerights/${selectedRoleId}`
        );
        const rightsData = response?.data?.data || [];
        setRoleRightsData(rightsData);
        rightsData.forEach((right) => {
          setValue(right, true);
        });
      } catch (error) {
        showToast("Error fetching role rights data", "error");
      }
    }
    if (selectedRoleId) {
      fetchData();
    }
  }, [selectedRoleId, setValue]);

  const onSubmit = async (data) => {
    
    const permissions = roleRightsData;
    
    try {
      const response = await axiosInstance.post(
        `web/addrolerights/${selectedRoleId}`,
        {
          permissions,
          mode_of_login: "Web",
        }
      );

      if (response?.data?.statusCode !== 200) {
        console.log(response?.data?.data);
        showToast(response?.data?.message, "error");
        return false;
      }

      showToast(response?.data?.message, "success");
    } catch (error) {
      showToast(error?.data?.message, "error");
    }
  };

  const userRightsMap = {
    user_all: ["user.view", "user.add", "user.edit", "user.export"],
    user_add: ["user.view", "user.add"],
    user_edit: ["user.view", "user.edit"],
    user_export: ["user.view", "user.export"],
    role_all: ["role.view", "role.add", "role.edit", "role.export"],
    role_add: ["role.view", "role.add"],
    role_edit: ["role.view", "role.edit"],
    role_export: ["role.view", "role.export"],
    company_all: [
      "company.view",
      "company.add",
      "company.edit",
      "company.export",
    ],
    company_add: ["company.view", "company.add"],
    company_edit: ["company.view", "company.edit"],
    company_export: ["company.view", "company.export"],
    location_all: [
      "location.view",
      "location.add",
      "location.edit",
      "location.export",
    ],
    location_add: ["location.view", "location.add"],
    location_edit: ["location.view", "location.edit"],
    location_export: ["location.view", "location.export"],
    checkpoint_all: [
      "checkpoint.view",
      "checkpoint.add",
      "checkpoint.edit",
      "checkpoint.export",
    ],
    checkpoint_add: ["checkpoint.view", "checkpoint.add"],
    checkpoint_edit: ["checkpoint.view", "checkpoint.edit"],
    checkpoint_export: ["checkpoint.view", "checkpoint.export"],
    shift_all: ["shift.view", "shift.add", "shift.edit", "shift.export"],
    shift_add: ["shift.view", "shift.add"],
    shift_edit: ["shift.view", "shift.edit"],
    shift_export: ["shift.view", "shift.export"],
    vendor_all: ["vendor.view", "vendor.add", "vendor.edit", "vendor.export"],
    vendor_add: ["vendor.view", "vendor.add"],
    vendor_edit: ["vendor.view", "vendor.edit"],
    vendor_export: ["vendor.view", "vendor.export"],
    designation_all: [
      "designation.view",
      "designation.add",
      "designation.edit",
      "designation.export",
    ],
    designation_add: ["designation.view", "designation.add"],
    designation_edit: ["designation.view", "designation.edit"],
    designation_export: ["designation.view", "designation.export"],
    rolerights_all: [
      "rolerights.view",
      "rolerights.add",
      "rolerights.edit",
      "rolerights.export",
    ],
    rolerights_add: ["rolerights.view", "rolerights.add"],
    rolerights_edit: ["rolerights.view", "rolerights.edit"],
    rolerights_export: ["rolerights.view", "rolerights.export"],
    companylocation_all: [
      "companylocation.view",
      "companylocation.add",
      "companylocation.edit",
      "companylocation.export",
    ],
    companylocation_add: ["companylocation.view", "companylocation.add"],
    companylocation_edit: ["companylocation.view", "companylocation.edit"],
    companylocation_export: ["companylocation.view", "companylocation.export"],
    livelocation_all: [
      "livelocation.view",
      "livelocation.add",
      "livelocation.edit",
      "livelocation.export",
    ],
    livelocation_add: ["livelocation.view", "livelocation.add"],
    livelocation_edit: ["livelocation.view", "livelocation.edit"],
    livelocation_export: ["livelocation.view", "livelocation.export"],
    tracklocation_all: [
      "tracklocation.view",
      "tracklocation.add",
      "tracklocation.edit",
      "tracklocation.export",
    ],
    tracklocation_add: ["tracklocation.view", "tracklocation.add"],
    tracklocation_edit: ["tracklocation.view", "tracklocation.edit"],
    tracklocation_export: ["tracklocation.view", "tracklocation.export"],
    attendance_all: [
      "attendance.view",
      "attendance.add",
      "attendance.edit",
      "attendance.export",
    ],
    attendance_add: ["attendance.view", "attendance.add"],
    attendance_edit: ["attendance.view", "attendance.edit"],
    attendance_export: ["attendance.view", "attendance.export"],
    firealertreport_all: [
      "firealertreport.view",
      "firealertreport.add",
      "firealertreport.edit",
      "firealertreport.export",
    ],
    firealertreport_add: ["firealertreport.view", "firealertreport.add"],
    firealertreport_edit: ["firealertreport.view", "firealertreport.edit"],
    firealertreport_export: ["firealertreport.view", "firealertreport.export"],
    gatelockreport_all: [
      "gatelockreport.view",
      "gatelockreport.add",
      "gatelockreport.edit",
      "gatelockreport.export",
    ],
    gatelockreport_add: ["gatelockreport.view", "gatelockreport.add"],
    gatelockreport_edit: ["gatelockreport.view", "gatelockreport.edit"],
    gatelockreport_export: ["gatelockreport.view", "gatelockreport.export"],
    trespassingreport_all: [
      "trespassingreport.view",
      "trespassingreport.add",
      "trespassingreport.edit",
      "trespassingreport.export",
    ],
    trespassingreport_add: ["trespassingreport.view", "trespassingreport.add"],
    trespassingreport_edit: [
      "trespassingreport.view",
      "trespassingreport.edit",
    ],
    trespassingreport_export: [
      "trespassingreport.view",
      "trespassingreport.export",
    ],
    panicmodereport_all: [
      "panicmodereport.view",
      "panicmodereport.add",
      "panicmodereport.edit",
      "panicmodereport.export",
    ],
    panicmodereport_add: ["panicmodereport.view", "panicmodereport.add"],
    panicmodereport_edit: ["panicmodereport.view", "panicmodereport.edit"],
    panicmodereport_export: ["panicmodereport.view", "panicmodereport.export"],
    salaryreport_all: [
      "salaryreport.view",
      "salaryreport.add",
      "salaryreport.edit",
      "salaryreport.export",
    ],
    salaryreport_add: ["salaryreport.view", "salaryreport.add"],
    salaryreport_edit: ["salaryreport.view", "salaryreport.edit"],
    salaryreport_export: ["salaryreport.view", "salaryreport.export"],
  };

//   const handleChange = (event) => {
//     const { id, checked } = event.target;
//     const value = event.target.value;

//     const userRights = userRightsMap[id];

//     if (userRights) {
//       userRights.forEach((right) => {
//         setValue(right, checked);
//       });

//       setRoleRightsData((prevData) =>
//         checked
//           ? [...prevData, ...userRights]
//           : prevData.filter((right) => !userRights.includes(right))
//       );
//     } else {
//       setRoleRightsData((prevData) =>
//         checked
//           ? [...prevData, value]
//           : prevData.filter((item) => item !== value)
//       );
//     }
//   };

const handleChange = (event) => {
    const { id, checked } = event.target;
    const value = event.target.value;
  
    const rights = userRightsMap[id];
  
    if (rights) {
      rights.forEach((right) => {
        setValue(right, checked);
      });
  
      setRoleRightsData((prevData) => {
        if (checked) {
          // Add the rights when checked
          return [...new Set([...prevData, ...rights])];
        } else {
          // Determine the base view right for the category (e.g., "user.view")
          const baseViewRight = rights.find((right) => right.endsWith(".view"));
          const rightsToRemove = rights.filter(
            (right) => right !== baseViewRight
          );
          return prevData.filter(
            (right) => !(rightsToRemove.includes(right) && rights.includes(right))
          );
        }
      });
    } else {
      setRoleRightsData((prevData) => {
        return checked
          ? [...new Set([...prevData, value])]
          : prevData.filter((item) => item !== value);
      });
    }
  };

// const handleChange = (event) => {
//     const { id, checked } = event.target;
//     const value = event.target.value;
  
//     const rights = userRightsMap[id];
  
//     if (rights) {
//       rights.forEach((right) => {
//         setValue(right, checked);
//       });
  
//       setRoleRightsData((prevData) => {
//         if (checked) {
//           // Add the rights when checked
//           return [...new Set([...prevData, ...rights])];
//         } else {
//           // Determine the base view right for the category (e.g., "user.view")
//           const baseViewRight = rights.find((right) => right.endsWith(".view"));
//           const rightsToRemove = rights.filter((right) => right !== baseViewRight);
  
//           // Check if all rights for this category are being unchecked
//           const isAllUnchecked = rights.every((right) => !prevData.includes(right));
  
//           return prevData.filter(
//             (right) => 
//               !(rightsToRemove.includes(right) && rights.includes(right)) &&
//               (!isAllUnchecked || right !== baseViewRight)
//           );
//         }
//       });
//     } else {
//       setRoleRightsData((prevData) => {
//         return checked
//           ? [...new Set([...prevData, value])]
//           : prevData.filter((item) => item !== value);
//       });
//     }
//   };

//   const handleChange = (event) => {
//     const { id, checked } = event.target;
//     const value = event.target.value;
  
//     const rights = userRightsMap[id];
  
//     if (rights) {
//       rights.forEach((right) => {
//         setValue(right, checked);
//       });
  
//       setRoleRightsData((prevData) => {
//         if (checked) {
//           // Add the rights when checked
//           return [...new Set([...prevData, ...rights])];
//         } else {
//           // Determine the base view right for the category (e.g., "user.view")
//           const baseViewRight = rights.find((right) => right.endsWith(".view"));
//           const rightsToRemove = rights.filter((right) => right !== baseViewRight);
  
//           // Include base view right if all rights are being unchecked
//           if (rightsToRemove.length === rights.length - 1) {
//             rightsToRemove.push(baseViewRight);
//           }
  
//           return prevData.filter((right) => !rightsToRemove.includes(right));
//         }
//       });
//     } else {
//       setRoleRightsData((prevData) => {
//         return checked
//           ? [...new Set([...prevData, value])]
//           : prevData.filter((item) => item !== value);
//       });
//     }
//   };
  
  

  return (
    <>
      <div className="main">
        <HeaderLayout title="Role Rights" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <main class="content">
            <div class="container-fluid p-0">
              <div class="row">
                <div class="col-6 colwidth">
                  <select
                    className="form-select py-2 w-100"
                    id="roles"
                    {...register("roles")}
                    defaultValue=""
                    selected
                  >
                    <option value="" disabled>
                      Select Role *
                    </option>
                    {roleOption?.map((ele) => (
                      <option value={ele.id}>{ele.name}</option>
                    ))}
                  </select>
                </div>
                <div class="col-6 colwidth">
                  <button
                    class="btn btn-lg btn-primary px-5 d-block ms-auto mt-1"
                    disabled={!selectedRoleId || selectedRoleId == 1}
                  >
                    Save
                  </button>
                </div>
                <div class="col-12 mt-4">
                  <div class="table-responsive">
                    <table class="table table-bordered bg-white table-hover">
                      <thead class="table-dark">
                        <tr>
                          <th>Title</th>
                          <th class="text-center">All</th>
                          <th class="text-center">View</th>
                          <th class="text-center">Add</th>
                          <th class="text-center">Edit</th>
                          <th class="text-center">Export</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Dashboard</td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input cross-field"
                                type="checkbox"
                                disabled
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="dashboard_view"
                                value="dashboard.view"
                                {...register("dashboard_view")}
                                checked={roleRightsData.includes(
                                  "dashboard.view"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input cross-field"
                                type="checkbox"
                                disabled
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input cross-field"
                                type="checkbox"
                                disabled
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input cross-field"
                                type="checkbox"
                                disabled
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th colspan="7">Master</th>
                        </tr>
                        <tr>
                          <td>User List</td>

                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="user_all"
                                value="user.all"
                                {...register("user_all")}
                                checked={
                                  roleRightsData.includes("user.view") &&
                                  roleRightsData.includes("user.add") &&
                                  roleRightsData.includes("user.edit") &&
                                  roleRightsData.includes("user.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="user_view"
                                value="user.view"
                                {...register("user_view")}
                                checked={
                                  roleRightsData.includes("user.view") ||
                                  roleRightsData.includes("user.add") ||
                                  roleRightsData.includes("user.edit") ||
                                  roleRightsData.includes("user.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="user_add"
                                value="user.add"
                                {...register("user_add")}
                                checked={roleRightsData.includes("user.add")}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="user_edit"
                                value="user.edit"
                                {...register("user_edit")}
                                checked={roleRightsData.includes("user.edit")}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="user_export"
                                value="user.export"
                                {...register("user_export")}
                                checked={roleRightsData.includes("user.export")}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Role Rights</td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="rolerights_all"
                                value="rolerights.all"
                                {...register("rolerights_all")}
                                checked={
                                  roleRightsData.includes("rolerights.view") &&
                                  roleRightsData.includes("rolerights.add") &&
                                  roleRightsData.includes("rolerights.edit") &&
                                  roleRightsData.includes("rolerights.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="rolerights_view"
                                value="rolerights.view"
                                {...register("rolerights_view")}
                                checked={
                                  roleRightsData.includes("rolerights.view") ||
                                  roleRightsData.includes("rolerights.add") ||
                                  roleRightsData.includes("rolerights.edit") ||
                                  roleRightsData.includes("rolerights.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="rolerights_add"
                                value="rolerights.add"
                                {...register("rolerights_add")}
                                checked={roleRightsData.includes("rolerights.add")}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="rolerights_edit"
                                value="rolerights.edit"
                                {...register("rolerights_edit")}
                                checked={roleRightsData.includes("rolerights.edit")}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="rolerights_export"
                                value="rolerights.export"
                                {...register("rolerights_export")}
                                checked={roleRightsData.includes("rolerights.export")}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Company List</td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="company_all"
                                value="company.all"
                                {...register("company_all")}
                                checked={
                                  roleRightsData.includes("company.view") &&
                                  roleRightsData.includes("company.add") &&
                                  roleRightsData.includes("company.edit") &&
                                  roleRightsData.includes("company.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="company_view"
                                value="company.view"
                                {...register("company_view")}
                                checked={
                                  roleRightsData.includes("company.view") ||
                                  roleRightsData.includes("company.add") ||
                                  roleRightsData.includes("company.edit") ||
                                  roleRightsData.includes("company.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="company_add"
                                value="company.add"
                                {...register("company_add")}
                                checked={roleRightsData.includes("company.add")}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="company_edit"
                                value="company.edit"
                                {...register("company_edit")}
                                checked={roleRightsData.includes(
                                  "company.edit"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="company_export"
                                value="company.export"
                                {...register("company_export")}
                                checked={roleRightsData.includes(
                                  "company.export"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Location List</td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="location_all"
                                value="location.all"
                                {...register("location_all")}
                                checked={
                                  roleRightsData.includes("location.view") &&
                                  roleRightsData.includes("location.add") &&
                                  roleRightsData.includes("location.edit") &&
                                  roleRightsData.includes("location.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="location_view"
                                value="location.view"
                                {...register("location_view")}
                                checked={
                                  roleRightsData.includes("location.view") ||
                                  roleRightsData.includes("location.add") ||
                                  roleRightsData.includes("location.edit") ||
                                  roleRightsData.includes("location.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="location_add"
                                value="location.add"
                                {...register("location_add")}
                                checked={roleRightsData.includes(
                                  "location.add"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="location_edit"
                                value="location.edit"
                                {...register("location_edit")}
                                checked={roleRightsData.includes(
                                  "location.edit"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="location_export"
                                value="location.export"
                                {...register("location_export")}
                                checked={roleRightsData.includes(
                                  "location.export"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Company Location List</td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="companylocation_all"
                                value="companylocation.all"
                                {...register("companylocation_all")}
                                checked={
                                  roleRightsData.includes(
                                    "companylocation.view"
                                  ) &&
                                  roleRightsData.includes(
                                    "companylocation.add"
                                  ) &&
                                  roleRightsData.includes(
                                    "companylocation.edit"
                                  ) &&
                                  roleRightsData.includes(
                                    "companylocation.export"
                                  )
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="companylocation_view"
                                value="companylocation.view"
                                {...register("companylocation_view")}
                                checked={
                                  roleRightsData.includes(
                                    "companylocation.view"
                                  ) ||
                                  roleRightsData.includes(
                                    "companylocation.add"
                                  ) ||
                                  roleRightsData.includes(
                                    "companylocation.edit"
                                  ) ||
                                  roleRightsData.includes(
                                    "companylocation.export"
                                  )
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="companylocation_add"
                                value="companylocation.add"
                                {...register("companylocation_add")}
                                checked={roleRightsData.includes(
                                  "companylocation.add"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="companylocation_edit"
                                value="companylocation.edit"
                                {...register("companylocation_edit")}
                                checked={roleRightsData.includes(
                                  "companylocation.edit"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="companylocation_export"
                                value="companylocation.export"
                                {...register("companylocation_export")}
                                checked={roleRightsData.includes(
                                  "companylocation.export"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Vendor List</td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="vendor_all"
                                value="vendor.all"
                                {...register("vendor_all")}
                                checked={
                                  roleRightsData.includes("vendor.view") &&
                                  roleRightsData.includes("vendor.add") &&
                                  roleRightsData.includes("vendor.edit") &&
                                  roleRightsData.includes("vendor.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="vendor_view"
                                value="vendor.view"
                                {...register("vendor_view")}
                                checked={
                                  roleRightsData.includes("vendor.view") ||
                                  roleRightsData.includes("vendor.add") ||
                                  roleRightsData.includes("vendor.edit") ||
                                  roleRightsData.includes("vendor.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="vendor_add"
                                value="vendor.add"
                                {...register("vendor_add")}
                                checked={roleRightsData.includes("vendor.add")}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="vendor_edit"
                                value="vendor.edit"
                                {...register("vendor_edit")}
                                checked={roleRightsData.includes("vendor.edit")}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="vendor_export"
                                value="vendor.export"
                                {...register("vendor_export")}
                                checked={roleRightsData.includes(
                                  "vendor.export"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Shift List</td>

                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="shift_all"
                                value="shift.all"
                                {...register("shift_all")}
                                checked={
                                  roleRightsData.includes("shift.view") &&
                                  roleRightsData.includes("shift.add") &&
                                  roleRightsData.includes("shift.edit") &&
                                  roleRightsData.includes("shift.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="shift_view"
                                value="shift.view"
                                {...register("shift_view")}
                                checked={
                                  roleRightsData.includes("shift.view") ||
                                  roleRightsData.includes("shift.add") ||
                                  roleRightsData.includes("shift.edit") ||
                                  roleRightsData.includes("shift.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="shift_add"
                                value="shift.add"
                                {...register("shift_add")}
                                checked={roleRightsData.includes("shift.add")}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="shift_edit"
                                value="shift.edit"
                                {...register("shift_edit")}
                                checked={roleRightsData.includes("shift.edit")}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="shift_export"
                                value="shift.export"
                                {...register("shift_export")}
                                checked={roleRightsData.includes(
                                  "shift.export"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Designation List</td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="designation_all"
                                value="designation.all"
                                {...register("designation_all")}
                                checked={
                                  roleRightsData.includes("designation.view") &&
                                  roleRightsData.includes("designation.add") &&
                                  roleRightsData.includes("designation.edit") &&
                                  roleRightsData.includes("designation.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="designation_view"
                                value="designation.view"
                                {...register("designation_view")}
                                checked={
                                  roleRightsData.includes("designation.view") ||
                                  roleRightsData.includes("designation.add") ||
                                  roleRightsData.includes("designation.edit") ||
                                  roleRightsData.includes("designation.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="designation_add"
                                value="designation.add"
                                {...register("designation_add")}
                                checked={roleRightsData.includes(
                                  "designation.add"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="designation_edit"
                                value="designation.edit"
                                {...register("designation_edit")}
                                checked={roleRightsData.includes(
                                  "designation.edit"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="designation_export"
                                value="designation.export"
                                {...register("designation_export")}
                                checked={roleRightsData.includes(
                                  "designation.export"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Checkpoint List</td>

                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="checkpoint_all"
                                value="checkpoint.all"
                                {...register("checkpoint_all")}
                                checked={
                                  roleRightsData.includes("checkpoint.view") &&
                                  roleRightsData.includes("checkpoint.add") &&
                                  roleRightsData.includes("checkpoint.edit") &&
                                  roleRightsData.includes("checkpoint.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="checkpoint_view"
                                value="checkpoint.view"
                                {...register("checkpoint_view")}
                                checked={
                                  roleRightsData.includes("checkpoint.view") ||
                                  roleRightsData.includes("checkpoint.add") ||
                                  roleRightsData.includes("checkpoint.edit") ||
                                  roleRightsData.includes("checkpoint.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="checkpoint_add"
                                value="checkpoint.add"
                                {...register("checkpoint_add")}
                                checked={roleRightsData.includes(
                                  "checkpoint.add"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="checkpoint_edit"
                                value="checkpoint.edit"
                                {...register("checkpoint_edit")}
                                checked={roleRightsData.includes(
                                  "checkpoint.edit"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="checkpoint_export"
                                value="checkpoint.export"
                                {...register("checkpoint_export")}
                                checked={roleRightsData.includes(
                                  "checkpoint.export"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Role List</td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="role_all"
                                value="role.all"
                                {...register("role_all")}
                                checked={
                                  roleRightsData.includes("role.view") &&
                                  roleRightsData.includes("role.add") &&
                                  roleRightsData.includes("role.edit") &&
                                  roleRightsData.includes("role.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="role_view"
                                value="role.view"
                                {...register("role_view")}
                                checked={
                                  roleRightsData.includes("role.view") ||
                                  roleRightsData.includes("role.add") ||
                                  roleRightsData.includes("role.edit") ||
                                  roleRightsData.includes("role.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="role_add"
                                value="role.add"
                                {...register("role_add")}
                                checked={roleRightsData.includes(
                                  "role.add"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="role_edit"
                                value="role.edit"
                                {...register("role_edit")}
                                checked={roleRightsData.includes(
                                  "role.edit"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="role_export"
                                value="role.export"
                                {...register("role_export")}
                                checked={roleRightsData.includes(
                                  "role.export"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th colspan="7">GPS Location</th>
                        </tr>
                        <tr>
                          <td>Live Location</td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="livelocation_all"
                                value="livelocation.all"
                                {...register("livelocation_all")}
                                checked={
                                  roleRightsData.includes(
                                    "livelocation.view"
                                  ) &&
                                  roleRightsData.includes("livelocation.add") &&
                                  roleRightsData.includes(
                                    "livelocation.edit"
                                  ) &&
                                  roleRightsData.includes("livelocation.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="livelocation_view"
                                value="livelocation.view"
                                {...register("livelocation_view")}
                                checked={
                                  roleRightsData.includes(
                                    "livelocation.view"
                                  ) ||
                                  roleRightsData.includes("livelocation.add") ||
                                  roleRightsData.includes(
                                    "livelocation.edit"
                                  ) ||
                                  roleRightsData.includes("livelocation.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="livelocation_add"
                                value="livelocation.add"
                                {...register("livelocation_add")}
                                checked={roleRightsData.includes(
                                  "livelocation.add"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="livelocation_edit"
                                value="livelocation.edit"
                                {...register("livelocation_edit")}
                                checked={roleRightsData.includes(
                                  "livelocation.edit"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="livelocation_export"
                                value="livelocation.export"
                                {...register("livelocation_export")}
                                checked={roleRightsData.includes(
                                  "livelocation.export"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Track Location</td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="tracklocation_all"
                                value="tracklocation.all"
                                {...register("tracklocation_all")}
                                checked={
                                  roleRightsData.includes(
                                    "tracklocation.view"
                                  ) &&
                                  roleRightsData.includes("tracklocation.add") &&
                                  roleRightsData.includes(
                                    "tracklocation.edit"
                                  ) &&
                                  roleRightsData.includes("tracklocation.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="tracklocation_view"
                                value="tracklocation.view"
                                {...register("tracklocation_view")}
                                checked={
                                  roleRightsData.includes(
                                    "tracklocation.view"
                                  ) ||
                                  roleRightsData.includes("tracklocation.add") ||
                                  roleRightsData.includes(
                                    "tracklocation.edit"
                                  ) ||
                                  roleRightsData.includes("tracklocation.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="tracklocation_add"
                                value="tracklocation.add"
                                {...register("tracklocation_add")}
                                checked={roleRightsData.includes(
                                  "tracklocation.add"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="tracklocation_edit"
                                value="tracklocation.edit"
                                {...register("tracklocation_edit")}
                                checked={roleRightsData.includes(
                                  "tracklocation.edit"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="tracklocation_export"
                                value="tracklocation.export"
                                {...register("tracklocation_export")}
                                checked={roleRightsData.includes(
                                  "tracklocation.export"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th colspan="7">Reports</th>
                        </tr>
                        <tr>
                          <td>Attendance</td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="attendance_all"
                                value="attendance.all"
                                {...register("attendance_all")}
                                checked={
                                  roleRightsData.includes("attendance.view") &&
                                  roleRightsData.includes("attendance.add") &&
                                  roleRightsData.includes("attendance.edit") &&
                                  roleRightsData.includes("attendance.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="attendance_view"
                                value="attendance.view"
                                {...register("attendance_view")}
                                checked={
                                  roleRightsData.includes("attendance.view") ||
                                  roleRightsData.includes("attendance.add") ||
                                  roleRightsData.includes("attendance.edit") ||
                                  roleRightsData.includes("attendance.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="attendance_add"
                                value="attendance.add"
                                {...register("attendance_add")}
                                checked={roleRightsData.includes(
                                  "attendance.add"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="attendance_edit"
                                value="attendance.edit"
                                {...register("attendance_edit")}
                                checked={roleRightsData.includes(
                                  "attendance.edit"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="attendance_export"
                                value="attendance.export"
                                {...register("attendance_export")}
                                checked={roleRightsData.includes(
                                  "attendance.export"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Fire Alert Report</td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="firealertreport_all"
                                value="firealertreport.all"
                                {...register("firealertreport_all")}
                                checked={
                                  roleRightsData.includes(
                                    "firealertreport.view"
                                  ) &&
                                  roleRightsData.includes(
                                    "firealertreport.add"
                                  ) &&
                                  roleRightsData.includes(
                                    "firealertreport.edit"
                                  ) &&
                                  roleRightsData.includes(
                                    "firealertreport.export"
                                  )
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="firealertreport_view"
                                value="firealertreport.view"
                                {...register("firealertreport_view")}
                                checked={
                                  roleRightsData.includes(
                                    "firealertreport.view"
                                  ) ||
                                  roleRightsData.includes(
                                    "firealertreport.add"
                                  ) ||
                                  roleRightsData.includes(
                                    "firealertreport.edit"
                                  ) ||
                                  roleRightsData.includes(
                                    "firealertreport.export"
                                  )
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="firealertreport_add"
                                value="firealertreport.add"
                                {...register("firealertreport_add")}
                                checked={roleRightsData.includes(
                                  "firealertreport.add"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="firealertreport_edit"
                                value="firealertreport.edit"
                                {...register("firealertreport_edit")}
                                checked={roleRightsData.includes(
                                  "firealertreport.edit"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="firealertreport_export"
                                value="firealertreport.export"
                                {...register("firealertreport_export")}
                                checked={roleRightsData.includes(
                                  "firealertreport.export"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Gate Lock Report</td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="gatelockreport_all"
                                value="gatelockreport.all"
                                {...register("gatelockreport_all")}
                                checked={
                                  roleRightsData.includes(
                                    "gatelockreport.view"
                                  ) &&
                                  roleRightsData.includes(
                                    "gatelockreport.add"
                                  ) &&
                                  roleRightsData.includes(
                                    "gatelockreport.edit"
                                  ) &&
                                  roleRightsData.includes(
                                    "gatelockreport.export"
                                  )
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="gatelockreport_view"
                                value="gatelockreport.view"
                                {...register("gatelockreport_view")}
                                checked={
                                  roleRightsData.includes(
                                    "gatelockreport.view"
                                  ) ||
                                  roleRightsData.includes(
                                    "gatelockreport.add"
                                  ) ||
                                  roleRightsData.includes(
                                    "gatelockreport.edit"
                                  ) ||
                                  roleRightsData.includes(
                                    "gatelockreport.export"
                                  )
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="gatelockreport_add"
                                value="gatelockreport.add"
                                {...register("gatelockreport_add")}
                                checked={roleRightsData.includes(
                                  "gatelockreport.add"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="gatelockreport_edit"
                                value="gatelockreport.edit"
                                {...register("gatelockreport_edit")}
                                checked={roleRightsData.includes(
                                  "gatelockreport.edit"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="gatelockreport_export"
                                value="gatelockreport.export"
                                {...register("gatelockreport_export")}
                                checked={roleRightsData.includes(
                                  "gatelockreport.export"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Trespassing Report</td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="trespassingreport_all"
                                value="trespassingreport.all"
                                {...register("trespassingreport_all")}
                                checked={
                                  roleRightsData.includes(
                                    "trespassingreport.view"
                                  ) &&
                                  roleRightsData.includes(
                                    "trespassingreport.add"
                                  ) &&
                                  roleRightsData.includes(
                                    "trespassingreport.edit"
                                  ) &&
                                  roleRightsData.includes(
                                    "trespassingreport.export"
                                  )
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="trespassingreport_view"
                                value="trespassingreport.view"
                                {...register("trespassingreport_view")}
                                checked={
                                  roleRightsData.includes(
                                    "trespassingreport.view"
                                  ) ||
                                  roleRightsData.includes(
                                    "trespassingreport.add"
                                  ) ||
                                  roleRightsData.includes(
                                    "trespassingreport.edit"
                                  ) ||
                                  roleRightsData.includes(
                                    "trespassingreport.export"
                                  )
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="trespassingreport_add"
                                value="trespassingreport.add"
                                {...register("trespassingreport_add")}
                                checked={roleRightsData.includes(
                                  "trespassingreport.add"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="trespassingreport_edit"
                                value="trespassingreport.edit"
                                {...register("trespassingreport_edit")}
                                checked={roleRightsData.includes(
                                  "trespassingreport.edit"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="trespassingreport_export"
                                value="trespassingreport.export"
                                {...register("trespassingreport_export")}
                                checked={roleRightsData.includes(
                                  "trespassingreport.export"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Panic Mode Report</td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="panicmodereport_all"
                                value="panicmodereport.all"
                                {...register("panicmodereport_all")}
                                checked={
                                  roleRightsData.includes(
                                    "panicmodereport.view"
                                  ) &&
                                  roleRightsData.includes(
                                    "panicmodereport.add"
                                  ) &&
                                  roleRightsData.includes(
                                    "panicmodereport.edit"
                                  ) &&
                                  roleRightsData.includes(
                                    "panicmodereport.export"
                                  )
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="panicmodereport_view"
                                value="panicmodereport.view"
                                {...register("panicmodereport_view")}
                                checked={
                                  roleRightsData.includes(
                                    "panicmodereport.view"
                                  ) ||
                                  roleRightsData.includes(
                                    "panicmodereport.add"
                                  ) ||
                                  roleRightsData.includes(
                                    "panicmodereport.edit"
                                  ) ||
                                  roleRightsData.includes(
                                    "panicmodereport.export"
                                  )
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="panicmodereport_add"
                                value="panicmodereport.add"
                                {...register("panicmodereport_add")}
                                checked={roleRightsData.includes(
                                  "panicmodereport.add"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="panicmodereport_edit"
                                value="panicmodereport.edit"
                                {...register("panicmodereport_edit")}
                                checked={roleRightsData.includes(
                                  "panicmodereport.edit"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="panicmodereport_export"
                                value="panicmodereport.export"
                                {...register("panicmodereport_export")}
                                checked={roleRightsData.includes(
                                  "panicmodereport.export"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Salary Report</td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="salaryreport_all"
                                value="salaryreport.all"
                                {...register("salaryreport_all")}
                                checked={
                                  roleRightsData.includes(
                                    "salaryreport.view"
                                  ) &&
                                  roleRightsData.includes("salaryreport.add") &&
                                  roleRightsData.includes(
                                    "salaryreport.edit"
                                  ) &&
                                  roleRightsData.includes("salaryreport.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="salaryreport_view"
                                value="salaryreport.view"
                                {...register("salaryreport_view")}
                                checked={
                                  roleRightsData.includes(
                                    "salaryreport.view"
                                  ) ||
                                  roleRightsData.includes("salaryreport.add") ||
                                  roleRightsData.includes(
                                    "salaryreport.edit"
                                  ) ||
                                  roleRightsData.includes("salaryreport.export")
                                }
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="salaryreport_add"
                                value="salaryreport.add"
                                {...register("salaryreport_add")}
                                checked={roleRightsData.includes(
                                  "salaryreport.add"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  selectedRoleId == 1 || selectedRoleId != 2
                                }
                                id="salaryreport_edit"
                                value="salaryreport.edit"
                                {...register("salaryreport_edit")}
                                checked={roleRightsData.includes(
                                  "salaryreport.edit"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div class="form-check d-flex justify-content-center align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                disabled={
                                  !selectedRoleId || selectedRoleId == 1
                                }
                                id="salaryreport_export"
                                value="salaryreport.export"
                                {...register("salaryreport_export")}
                                checked={roleRightsData.includes(
                                  "salaryreport.export"
                                )}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </form>
        <FooterLayout />
      </div>
    </>
  );
};

export default RoleRights;
